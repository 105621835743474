import {createRouter, createWebHashHistory} from 'vue-router'
import Cookies from "vue-cookies";
import {useUserStore} from '@/stores/modules/user';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const routes = [
    {
        path: '/',
        name: 'home',
        meta: {title: '首页'},
        component: () => import('../views/home/homePage')
    }, {
        path: '/custom',
        name: 'custom',
        meta: {title: '商品库'},
        component: () => import('../views/home/custom'),
    }, {
        path: '/publicGoods',
        name: 'publicGoods',
        meta: {title: '产品'},
        component: () => import('../views/home/publicGoods'),
    },
    {
        path: '/openDetails',
        name: 'openDetails',
        meta: {title: '商品详情'},
        component: () => import('../views/home/details'),
    },
    {
        path: '/login',
        name: 'login',
        meta: {title: '登录'},
        component: () => import('../views/login')
    },
    {
        path: '/doc',
        name: 'doc',
        meta: {title: '文档'},
        component: () => import('../views/doc')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

function dynamicRouter(routers) {
    return routers.map((itemRouter) => {
        const route = {
            path: itemRouter.path,
            name: itemRouter.name,
            meta: {title: itemRouter.title},
            component: () => import(`@/views/${itemRouter.component}`).then(m => m.default || m),
            children: [],
        };
        // 是否存在子集
        if (itemRouter.son && itemRouter.son.length) {
            route.children = dynamicRouter(itemRouter.son);
        }
        return route;
    });
}

//守卫
let whiteList = ["/login", "/", "/custom", "/openDetails", "/publicGoods", "/doc"];
router.beforeEach(async (to, from, next) => {
// 基础标题
    let baseTitle = to.meta.title ? to.meta.title : '大众定制';

    // 如果查询参数中包含产品标题，追加产品标题
    if (to.query.productTitle) {
        baseTitle += `-${to.query.productTitle}`;
    } else {
        baseTitle += '-大众定制';
    }

    document.title = baseTitle;

//读取token
    let token = Cookies.get('user_token')
    const isLayoutPath = router.getRoutes().some(route => route.path.startsWith('/layout'));
//未登录
    if (!token) {
        if (whiteList.indexOf(to.path) === -1) {
            Cookies.remove('user_token')
            localStorage.removeItem('user_info')
            return next(`/login`)
        } else {
            NProgress.start()
            return next()
        }
    } else {
        if (!isLayoutPath || useUserStore().menus.length === 0) {
            try {
                await useUserStore().getMenus();
                let newMenus = JSON.parse(JSON.stringify(useUserStore().menus))
                const layoutRoute = {
                    path: '/layout',
                    name: 'layout',
                    component: () => import('@/layout'),
                    children: [], // 这里先留一个空数组，后面动态添加
                };
                layoutRoute.children = dynamicRouter(newMenus);
                router.addRoute(layoutRoute);

                //有token并且在登录页面，直接进入管理首页
                if(to.path == '/login'){
                    next(`/main`)
                }
                NProgress.start()
                return next({...to, replace: true})
            } catch (error) {
                return next(`/login`);
            }
        } else {
            NProgress.start()
            return next();
        }
    }
});

router.afterEach((to, from) => {
    NProgress.done()
})

export default router
