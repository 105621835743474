import axios from 'axios'
import { ElMessage } from 'element-plus'
import Cookies from "vue-cookies";
import router from "@/router";
const packageJson = require('../../package.json');

const baseUrlStr = process.env.VUE_APP_BASE_API + '/' + process.env.VUE_APP_BASE_VERSION

// 创建一个 axios 实例
const service = axios.create({
    baseURL: baseUrlStr, // 所有的请求地址前缀部分
    timeout: 60000, // 请求超时时间毫秒
    withCredentials: false, // 异步请求携带cookie
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
})

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {

        console.log("version",packageJson.version)

        // 在发送请求之前做些什么
        if ( !navigator.onLine ) {
            ElMessage({
                message: '请检查您的网络是否正常',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject( new Error( '请检查您的网络是否正常' ) )
        }

        let token = Cookies.get('user_token')
        if (token) {
            config.headers['X-Token'] = token
        }
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(function (response) {
        const result = response.data
        const type = Object.prototype.toString.call( result )
        // 如果是文件流 直接返回
        if ( type === '[object Blob]' || type === '[object ArrayBuffer]' ) {
            return result
        } else {
            const { return_code, message } = result
            if (return_code !== 0) {
                ElMessage({
                    message: message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
                return Promise.reject(new Error(message || 'Error'))
            }
            return result.result
        }

        return result

    },
    function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么

        if (error.response.status == 401) {
            ElMessage( {
                message : '登录超时，请重新登录!!',
                type : 'error',
                duration : 2 * 1000
            })

            //版本号不一致，或者登录超时进行强制刷新页面
            // location.reload([true])
            Cookies.remove('user_token')
            localStorage.removeItem('user_info')
            router.push('/login')
        } else {
            const message = error.response.data.message || error.message
            const isTimeout = error.message.includes( 'timeout' )
            ElMessage( {
                message : isTimeout ? '网络请求超时' : message || '连接到服务器失败',
                type : 'error',
                duration : 2 * 1000
            } )
            throw new Error()
            return false;
        }
    }
)

export default service
